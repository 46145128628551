<template>
  <section aria-labelledby="quick-links-title">
    <div class="p-6">
      <div class="md:flex md:items-center md:justify-between">
        <h2 class="text-xl font-bold text-gray-900 sm:text-2xl">Profile</h2>
      </div>
      <p class="mt-2 text-sm text-gray-500">
        Your display name and profile photo will be shown publicly.
      </p>

      <form @submit="save">
        <div class="mt-8">
          <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div class="sm:col-span-4">
              <label for="email" class="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div class="mt-1">
                <input id="email" v-model="profile.email" type="email" autocomplete="email" class="shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>

            <div class="sm:col-span-4">
              <label for="username" class="block text-sm font-medium text-gray-700">
                Display name
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input type="text" v-model="profile.displayName" id="username" autocomplete="username" class="flex-1 focus:ring-pink-500 focus:border-pink-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
              </div>
            </div>

            <div class="sm:col-span-6">
              <label for="photo" class="block text-sm font-medium text-gray-700">
                Photo
              </label>
              <div class="mt-1 flex items-center">
                <span class="h-14 w-14 rounded-full overflow-hidden bg-gray-100">
                  <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </span>
                <button type="button" class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                  Change
                </button>
              </div>
            </div>

            <div class="sm:col-span-4">
              <div class="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                  </div>
                  <div class="ml-3">
                    <p class="text-sm text-yellow-700">
                      You can change your photo once you have a <span class="tracking-widest">💎 🙌 🏨</span>&nbsp;&nbsp;NFT
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="flex">
            <DBtn type="submit" color="blue" primary :loading="loading">
              Save
            </DBtn>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import { updateProfile, ownProfile } from '@/gql/auth'
import { ExclamationIcon } from '@heroicons/vue/solid'
import { DBtn } from '@ui'

const store = useStore()
const id = store.getters['auth/id']

const { result } = useQuery(ownProfile, { id })
const profile = useResult(result, {
  id,
  email: '',
  displayName: '',
  avatarImg: '',
}, ({ profile, account }) => ({
  id: account.id,
  email: account.email,
  displayName: profile.displayName,
  avatarImg: profile.avatarImg,
}))

const { mutate, loading } = useMutation(updateProfile, () => ({
  variables: profile.value
}))

const save = async (e) => {
  e.preventDefault()
  try {
    await mutate()
    notify({ type: 'success', text: 'Profile saved' })
  } catch (err) {
    if (err.message.includes('accounts_email_key')) {
      notify({ type: 'error', title: 'Invalid email', text: 'Already used by another account' })
    } else if (err.message.includes('profiles_displayName_key')) {
      notify({ type: 'error', title: 'Invalid name', text: 'Already used by another account' })
    } else {
      notify({ type: 'error', title: 'Unexpected error', text: err.message })
    }
  }
}
</script>